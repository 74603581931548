<template>
  <section class="new_css">
    <el-row class="new_header">
      <el-col
        v-if="buttonList.length > 0"
        :span="24"
        class="organizetoolbar"
        style="padding-bottom: 0px"
      >
        <el-form
          :inline="true"
          ref="selectForm"
          :model="selectForm"
          class="select_form"
        >
          <el-form-item label="人员名称:">
            <el-input v-model="selectForm.person" placeholder="人员名称"></el-input>
          </el-form-item>
          <el-form-item label="简述:">
            <el-input v-model="selectForm.sketch" placeholder="简述"></el-input>
          </el-form-item>
          <toolbar
            :buttonList="buttonList"
            @callFunction="callFunction"
            :buttonListmsg="buttonListmsg"
          ></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div class="calc-height">
      <!--列表-->
      <el-table
        :data="users"
        highlight-current-row
        height="100%"
     
        @current-change="selectCurrentRow"
        @row-dblclick="handleShow"
        class="new_table"
      >
        <el-table-column label="字号" prop="OfficialNumber"></el-table-column>
        <el-table-column prop="NotifyName" label="通知名称"> </el-table-column>

        <el-table-column
          label="签批日期"
          prop="AgreementDate"
          :formatter="formatStartTime"
        ></el-table-column>
        <el-table-column
          label="公示日期"
          prop="PublicityDate"
          :formatter="formatStartTime"
        ></el-table-column>
        <el-table-column
          label="调整⽇期"
          prop="AdjustDate"
          :formatter="formatStartTime"
        ></el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination
      align="center"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, jumper ,total"
      :page-count="total"
      :total="totaldata"
    >
    </el-pagination>
    <el-dialog
      :title="operation ? '添加' : '编辑'"
      :visible.sync="SakaryVisible"
      v-model="SakaryVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="SakaryForm"
        :model="SakaryForm"
        class="demo-form-inline"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item
              label=" 字号:"
              prop="OfficialNumber"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input
                type="text"
                v-model="SakaryForm.OfficialNumber"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="通知名称:"
              prop="NotifyName"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input type="text" v-model="SakaryForm.NotifyName"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="签批日期:"
              prop="AgreementDate"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-date-picker
                type="date"
                v-model="SakaryForm.AgreementDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="公示日期:"
              prop="PublicityDate"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-date-picker
                type="date"
                v-model="SakaryForm.PublicityDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="调整⽇期:"
              prop="AdjustDate"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-date-picker
                type="date"
                v-model="SakaryForm.AdjustDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="内容简述">
              <el-input type="textarea" v-model="SakaryForm.Sketch"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注">
              <el-input type="textarea" v-model="SakaryForm.Remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="涉及⼈员" label-width="120px">
              <el-button @click="chooseUserList()">选择涉及⼈员</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-table
            :data="SakaryForm.Persons"
            style="width: 100%"
            class="bg-color"
          >
            <el-table-column prop="UserName" label="姓名"></el-table-column>
            <el-table-column prop="Factory" label="公司"></el-table-column>
            <el-table-column prop="Dpt" label="部门"></el-table-column>
            <el-table-column prop="Post" label="职位"></el-table-column>
            <el-table-column prop="optType" label="类型"></el-table-column>
            <el-table-column label="操作" width="150">
              <template scope="scope">
                <el-button
                  size="small"
                  v-on:click="PersonsDelete(scope.row)"
                  type="text"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <upload-files
          :files="SakaryForm.Files"
          :key="key"
          action="/cyl/ftp/ftp/upload"
          :limit="20"
          @fun="dealFiles"
          :IsDisabled="operation1"
          :IsDel="operation1"
        ></upload-files>
        <el-row v-if="operation1">
          <el-col :span="24" style="text-align: center">
          
              <el-button type="primary" v-on:click="onSubmit('SakaryForm')"
                >提交</el-button
              >
           
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <el-dialog
    title="选择人员"
      :visible.sync="addUserVisible"
      v-model="addUserVisible"
      width="75%"
      center
      append-to-body
    >
      <UserChoose
        :data="choosedusers"
        :all="true"
        :key="key"
        :single="false"
        @callback="usercallFunction"
      ></UserChoose>
    </el-dialog>
    <el-dialog
      :visible.sync="addUserListVisible"
      v-model="addUserListVisible"
      center
      append-to-body
    >
      <el-form
        ref="SakaryUserForm"
        :model="SakaryForm"
        class="demo-form-inline"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="类型:"
              prop="optType"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-select
                v-model="SakaryUserForm.optType"
                placeholder="请选择"
                style="“width: 100%”"
                @change="OptTypeModel($event)"
              >
                <el-option label="任职" value="任职"></el-option>
                <el-option label="免职" value="免职"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="名称:"
              prop="UserName"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input
                type="text"
                :disabled="true"
                v-model="SakaryUserForm.UserName"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="chooseUser"
                ></el-button>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="mianzhi">
          <el-col :span="12">
            <el-form-item label="免职岗位:">
              <el-select
                v-model="SakaryUserForm.Post"
                @change="PositionModel($event)"
                placeholder="岗位"
                style="width: 100%"
              >
                <el-option
                  v-for="item in PositionLists"
                  :key="item.Id"
                  :label="item.PostName"
                  :value="item.PostName"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="renzhi">
          <el-col :span="12">
            <el-form-item label="任职岗位:">
              <el-input
                type="text"
                :disabled="true"
                v-model="SakaryUserForm.Post"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="IntochooseUser"
                ></el-button>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" style="text-align: center">
            <el-form-item label-width="0px">
              <el-button type="primary" v-on:click="onUserSubmit()"
                >确认选择</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog
    title="选择职位"
      :visible.sync="IntoUserVisible"
      v-model="IntoUserVisible"
      width="75%"
      center
      append-to-body
    >
      <UserChoosejob
        :data="Intochoosedusers"
        :key="key"
        :all="true"
        :single="false"
        @callback="IntousercallFunction"
      ></UserChoosejob>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import UserChoose from "../../components/UserChoose";
import UserChoosejob from "../../components/UserChoosejob";
import UploadFiles from "../../components/UploadFiles";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
export default {
  components: { Toolbar, UserChoose, UserChoosejob, UploadFiles },
  data() {
    return {
      addUserListVisible: false,
      loading: true,
      mianzhi: false,
      renzhi: false,
      searchVal: "",
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      key: 0,
      SakaryVisible: false,
      buttonListmsg: "",
      users: [],
      choosedusers: [],
      Intochoosedusers: [],
      operation: false, // true:新增, false:编辑
      operation1: true,
      addUserVisible: false,
      IntoUserVisible: false,
      buttonList: [],
      PositionLists: [],
      SakaryForm: {
        OfficialNumber: null,
        NotifyName: null,
        AgreementDate: null,
        PublicityDate: null,
        AdjustDate: null,
        Sketch: null,
        Remark: null,
        Persons: [],
        Files: [],
      },
      SakaryUserForm: {
        UserId: null,
        UserName: null,
        Factory: null,
        Dpt: null,
        Post: null,
        PostId: null,
          optType: null,
          UserPostId: null,
      },
      selectForm: {
        sketch: "",
        person: "",
      },
    };
  },
  methods: {
    PositionModel(id) {
      console.log(id);
      console.log(this.PositionLists);
      var obj = {};
      obj = this.PositionLists.find((item) => {
        //model就是上面的数据源
        if (item.PostName === id) {
          return item;
        }
      });
      console.log(obj);

      this.SakaryUserForm.DptId = obj.DepartmentId;
      this.SakaryUserForm.PostId = obj.PostId;
      this.SakaryUserForm.Factory = obj.OrganizationName;
      this.SakaryUserForm.Dpt = obj.DepartmentName;
      this.SakaryUserForm.Post = obj.PostName;
      this.SakaryUserForm.UserPostId = obj.UserPostId;
      //  this.PermissionForm.PostSalaryId = obj.Id
    },
    IntochooseUser() {
      this.IntoUserVisible = true;
    },
    IntousercallFunction(newdata) {
      this.SakaryUserForm.Post = newdata.Name;
      this.SakaryUserForm.DptId = newdata.DepartmentId;
      this.SakaryUserForm.PostId = newdata.Id;

        this.$api.GetDepartmentOrg({ deptId: newdata.DepartmentId }).then((res) => {
        if (res.data.code == 200) {
            if (res.data.data.deptName != null) {
                this.SakaryUserForm.Factory = res.data.data.deptName;
          } else {
            this.SakaryUserForm.Factory = "";
          }
            this.SakaryUserForm.Dpt = res.data.data.queryDeptName;
        } else {
          this.$message({
            message: "获取部门信息出错",
            type: "error",
            duration: 5000,
          });
        }
      });
      this.IntoUserVisible = false;
    },
    OptTypeModel(val) {
      if (val == "任职") {
        this.renzhi = true;
        this.mianzhi = false;
      } else if (val == "免职") {
        this.mianzhi = true;
        this.renzhi = false;
      }
    },
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    PersonsDelete(row) {
      this.$confirm("确认要删除吗？", "提示", {
        type: "warning",
      }).then((_) => {
        this.SakaryForm.Persons.splice(this.SakaryForm.Persons.indexOf(row), 1);
      });
    },
    usercallFunction(newdata) {
      console.log(newdata);
      if (newdata.length > 1) {
        this.$message({
          message: "不允许选择多人",
          type: "error",
        });
        return;
        }
        console.log("选人之后")
      this.SakaryUserForm.UserId = newdata[0].Id;
      this.SakaryUserForm.UserName = newdata[0].UserNameClaim;
        this.$api.GetUserPostDptOrgList({ userId: newdata[0].Id, onlyPrincipal: false }).then(
        (res) => {
          console.log(res.data.response);
          this.PositionLists = res.data.response;
        }
      );

      this.addUserVisible = false;
    },
    chooseUser() {
      this.addUserVisible = true;
    },
    chooseUserList() {
      (this.SakaryUserForm = {
        UserId: null,
        UserName: null,
        Factory: null,
        Dpt: null,
        Post: null,
        PostId: null,
        optType: null,
      }),
        (this.addUserListVisible = true);
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    onUserSubmit() {
      this.SakaryForm.Persons.push(this.SakaryUserForm);
      this.addUserListVisible = false;
    },
    //获取列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        person: this.selectForm.person,
        sketch: this.selectForm.sketch,
      };

      this.$api.OfficialPersonList(para).then((res) => {
        console.log(res);
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      this.$api.OfficialPersonInfo({ id: row.Id }).then((res) => {
        console.log(res);
        this.SakaryForm = res.data.response;
        this.key += 1;
        this.SakaryVisible = true;
      });

      this.operation = false;
      this.operation1 = true;
    },
    handleShow() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      OfficialPersonInfo({ id: row.Id }).then((res) => {
        console.log(res);
        this.SakaryForm = res.data.response;
        this.key += 1;
        this.SakaryVisible = true;
      });

      this.operation = false;
      this.operation1 = false;
    },
    handleAdd() {
      (this.SakaryForm = {
        OfficialNumber: null,
        NotifyName: null,
        AgreementDate: null,
        PublicityDate: null,
        AdjustDate: null,
        Sketch: null,
        Remark: null,
        Persons: [],
        Files: [],
      }),
        (this.key += 1);
      (this.operation = true), (this.SakaryVisible = true);
      this.operation1 = true;
    },
    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let para = Object.assign({}, this.SakaryForm);
            if (this.operation) {
              //新增
              this.$api.OfficialPersonAdd(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: "添加成功",
                    type: "success",
                    duration: 5000,
                  });
                  this.SakaryVisible = false;
                  this.getData();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                    duration: 5000,
                  });
                }
              });
            } else {
              //修改
              this.$api.OfficialPersonUpdate(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: "修改成功",
                    type: "success",
                    duration: 5000,
                  });
                  this.SakaryVisible = false;
                  this.getData(false);
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                    duration: 5000,
                  });
                }
              });
            }
          } else {
            this.$message({
              message: "请检查必填项",
              type: "error",
              duration: 5000,
            });
            return false;
          }
        });
      });
    },
    dealFiles(data) {
      console.log(data.backData);
      this.SakaryForm.Files = data.backData;
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>

